import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import List from '@mui/material/List';
import moment from 'moment';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import DisbursementEntries from './DisbursementEntries';
import { Card } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { IsRefundDisbursement } from '../constants/Disbursement';

const useStyles = makeStyles((theme) => ({
	innerExpandable: {
		fontFamily: 'Roboto',
		fontWeight: 500,
		fontSize: 16,
		lineHeight: '14px',
		color: '#FFFFFF'
	},
	disbursement: {
		fontFamily: 'Roboto',
		fontWeight: 400,
		fontSize: 17,
		lineHeight: '14px',
		color: '#404547'
	}

}));

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
        theme.palette.mode === 'dark'
        	? 'rgba(255, 255, 255, .05)'
        	: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const ControlledTooltip = styled(({ className, ...props }) => (
	<Tooltip arrow
		{...props} classes={{ popper: className }}
		disableFocusListener
		disableHoverListener
		disableTouchListener
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#263238',
		color: '#FFFFFF',
		boxShadow: theme.shadows[1],
		fontSize: 15,
		Transparency: '85%',
		padding: 25
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const DisbursementInfo = ({
	disbursementsEntriesInfo = [],
	processed = '',
	amount = 0,
	expandAll = false,
	merchantInfo = {},
	setDisbursementRow = () => {},
	disbursementRow = () => {},
	setExpandAllToolTip = () => {},
	refund = 0,
	returned = 0,
	chargeback = 0,
	fees = 0
}) => {

	const [ expanded, setExpanded ] = useState();
	const [ open, setOpen ] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		setOpen(expandAll);
		if (expandAll) setExpanded('panel1');
		else  setExpanded(false);
	}, [ expandAll ]);

	const handleClick = () => {
		setOpen(!open);
	};

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};
	const handleTooltipClose = () => {
		setDisbursementRow(false);
	};

	const totalAmount = _.sumBy(disbursementsEntriesInfo, (disbursementsEntry) => {
		if (IsRefundDisbursement(disbursementsEntry.event)) {
			return 0;
		}
		return parseFloat(disbursementsEntry.transactionInfo?.originalAmount ?? 0)
	})

	const totalSurcharge = _.sumBy(disbursementsEntriesInfo, (disbursementsEntry) => {
		if (IsRefundDisbursement(disbursementsEntry.event)) {
			return 0;
		}
		return parseFloat(disbursementsEntry.transactionInfo?.surcharge ?? 0)
	})

	const netTotal = totalAmount + totalSurcharge - fees
	const currencyFormat = { style: 'currency', currency: 'USD', minimumFractionDigits: 2 };

	return (<>
		<Card >
			<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
				<AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
					<Grid container direction='row'>
						<Grid item lg={3} sm={4} md={5}>
							<ControlledTooltip
								title={
									<>
										<Typography>
                                            Click on any Disbursement row to see the transaction details.
										</Typography>
										<Grid container direction='row' style={{ marginTop: '1.5%' }}>
											<Grid item sm={6} style={{ marginTop: '1.5%' }}> 3 of 5</Grid>
											<Grid item sm={6}>
												<Grid container direction='row' justifyContent='flex-end'>
													<Button id='disburse-skip'
														style={{ fontFamily: 'Roboto',
															fontWeight: 700,
															color: '#CDDBFF'
														}}
														onClick={handleTooltipClose}
													>
                                                        Skip
													</Button>
													<Button id='disburse-next' style={{
														backgroundColor: '#CDDBFF',
														borderRadius: 4,
														fontFamily: 'Roboto',
														fontWeight: 700,
														color: '#151D33'
													}}
													onClick={(e) => {
														handleTooltipClose();
														setExpandAllToolTip(true);
														e.stopPropagation();
														e.preventDefault();
													}
													}
													>
                                                        Next
													</Button>
												</Grid>
											</Grid>

										</Grid>
									</>
								}
								open={disbursementRow}
								onClose={handleTooltipClose}
							>
								<Typography className={classes.disbursement}>
                                Disbursement Date:

									<span style={{ color: '#0D5DB3', marginLeft: '0.75%' }}>
										{moment(processed).format('MM/DD/YYYY')}
									</span>
									<span >
                                    - {disbursementsEntriesInfo.length} transactions
									</span>

								</Typography>
							</ControlledTooltip>
						</Grid>
						<Grid item lg={8} md={6} sm={7} style={{ marginLeft: '1%' }}>
							<Grid container direction='row' spacing={2} justifyContent={'flex-end'}>

								<Grid item>
									<Typography className={classes.disbursement}>
									Fee: <span style={{ fontWeight: 'bold' }}>
										{`-${fees.toLocaleString('en-US', currencyFormat)}`}
									</span>
									</Typography>
								</Grid>

								<Grid item>
								<Typography  className={classes.disbursement}>
									Deducted:
									<span style={{ fontWeight: 'bold' }}>
										{`-${Math.abs((refund + returned + chargeback) / 100).toLocaleString('en-US', currencyFormat)}`}
									</span>
								</Typography>
								</Grid>

								<Grid item>
								<Typography className={classes.disbursement}>
                                    Net Total: <span style={{ fontWeight: 'bold' }}>
										{netTotal.toLocaleString('en-US', currencyFormat)}
									</span>
								</Typography>
								</Grid>

							</Grid>
						</Grid>
					</Grid>
				</AccordionSummary>
				<AccordionDetails >
					<List component="nav" aria-labelledby="nested-list-subheader" style={{ backgroundColor: '#747779' }}>
						<Grid container direction='row' style={{ width: '95%', marginLeft: '2%' }}>
							<Grid item lg={2} md={2}  onClick={handleClick} sm={2}>
								<Grid container direction='row'>
									<Typography className={classes.innerExpandable}>
                                        Tran Date
									</Typography>
								</Grid>
							</Grid>
							<Grid lg={10} md={10} sm={10} >
								<Grid container direction='row' style={{ marginLeft: '10px', fontSize: '15px' }} justifyContent={'space-between'} spacing={1}>
									<Grid item lg={2} md={2}>
										<Typography sx={{ fontSize: '15px' }} className={classes.innerExpandable}>
											{merchantInfo.entity}
										</Typography>
									</Grid>
									<Grid item lg={1} md={1}>
										<Typography sx={{ fontSize: '15px' }} className={classes.innerExpandable}>
                                            Method
										</Typography>
									</Grid>
									<Grid item lg={1} md={1}>
										<Typography sx={{ fontSize: '15px' }} className={classes.innerExpandable}>
                                            Payee
										</Typography>
									</Grid>
									<Grid item lg={1} md={1}>
										<Typography sx={{ fontSize: '15px' }} className={classes.innerExpandable}>
                                            Amount
										</Typography>
									</Grid>
									<Grid item lg={1} md={1}>
										<Typography sx={{ fontSize: '15px' }} className={classes.innerExpandable}>
                                            Surcharge
										</Typography>
									</Grid>
									<Grid item lg={1} md={1}>
										<Typography sx={{ fontSize: '15px' }} className={classes.innerExpandable}>
                                            Subtotal
										</Typography>
									</Grid>
									<Grid item lg={1} md={1}>
										<Typography sx={{ fontSize: '15px' }} className={classes.innerExpandable} >
                                            Fee
										</Typography>
									</Grid>
									<Grid item lg={1} md={1}>
										<Typography sx={{ fontSize: '15px' }} className={classes.innerExpandable} >
                                            Deducted
										</Typography>
									</Grid>
									<Grid item lg={1} md={1}>
										<Typography sx={{ fontSize: '15px' }} className={classes.innerExpandable} >
                                            Net Total
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<div style={{ backgroundColor: '#ECEDED', maxWidth: '100%' }}>
							{_.map(disbursementsEntriesInfo, (disbursementEntriesDetails, index) =>{
								return <DisbursementEntries
									disbursementEntriesDetails={disbursementEntriesDetails}
									key={index}
									merchantInfo={merchantInfo}
								/>;

							})}
						</div>
					</List>
				</AccordionDetails>
			</Accordion>
		</Card>

	</>);
};

DisbursementInfo.propTypes = {
	disbursementsEntriesInfo: PropTypes.array,
	processed: PropTypes.string,
	amount: PropTypes.number,
	expandAll: PropTypes.bool,
	merchantInfo: PropTypes.object,
	setDisbursementRow: PropTypes.func,
	disbursementRow: PropTypes.func,
	setExpandAllToolTip: PropTypes.func,
	refund: PropTypes.any,
	fees: PropTypes.number
};

export default DisbursementInfo;
